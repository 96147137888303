module.exports = [{
      plugin: require('/zeit/772bf082/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/772bf082/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/772bf082/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/zeit/772bf082/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#008080","theme_color":"#008080","display":"minimal-ui","icon":"src/images/dog-face.svg"},
    },{
      plugin: require('/zeit/772bf082/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"zY0FRikEko3GFGl5wWnbgUFrCgjrO5cn","devKey":"kOc7p1zYXQh46HK7aky6jpPFnqGtz4db","trackPage":true},
    },{
      plugin: require('/zeit/772bf082/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
