// default theme preset

const LARGER_DISPLAY_WIDTH = "1600px"
const LARGE_DISPLAY_WIDTH = "1280px"
const DEFAULT_WIDTH = "980px"
const TABLET_WIDTH = "768px"
const MOBILE_WIDTH = "480px"
/* const LARGER_DISPLAY_MEDIA_QUERY = "@media only screen and (max-width:1600px)"
const LARGE_DISPLAY_MEDIA_QUERY = "@media only screen and (max-width:1280px)"
const DEFAULT_MEDIA_QUERY = "@media only screen and (max-width:980px)"
const TABLET_MEDIA_QUERY = "@media only screen and (max-width:768px)"
const MOBILE_MEDIA_QUERY = "@media only screen and (max-width:480px)"

const MIN_LARGER_DISPLAY_MEDIA_QUERY = "@media (min-width:1600px)"
const MIN_LARGE_DISPLAY_MEDIA_QUERY = "@media (min-width:1280px)"
const MIN_DEFAULT_MEDIA_QUERY = "@media (min-width:980px)"
const MIN_TABLET_MEDIA_QUERY = "@media (min-width:768px)"
const MIN_MOBILE_MEDIA_QUERY = "@media (min-width:480px)" */

const breakpoints = [
  MOBILE_WIDTH,
  TABLET_WIDTH,
  DEFAULT_WIDTH,
  LARGE_DISPLAY_WIDTH,
  LARGER_DISPLAY_WIDTH,
]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const space = [0, 4, 8, 16, 32, 48, 64, 128, 256, 512]
space.small = space[1]
space.medium = space[2]
space.large = space[3]

/* const sizes = ["108px", "216px", "432px", "864px"]
sizes.sm = sizes[0]
sizes.md = sizes[1]
sizes.lg = sizes[2]
sizes.xl = sizes[3] */

//base colors
const romance = "rgba(251, 254, 254, 1)" // based off primary brand color
const ebony = "rgba(48, 53, 54, 1)"

//primary set of colors
const mediumTurquoise = "rgba(75, 209, 226, 1)"
const lightseaGreen = "rgba(27, 154, 170, 1)" //primary brand color
const eden = "rgba(13, 76, 84, 1)"
const daintree = "rgba(6, 36, 40, 1)"
const lightseaGreen004 = "rgba(27, 154, 170, 0.04)"
const lightseaGreen008 = "rgba(27, 154, 170, 0.08)"
const lightseaGreen016 = "rgba(27, 154, 170, 0.16)"

//secondary set of colors
const rosebonbon = "rgba(251, 70, 156, 1)"
const dogwoodRose = "rgba(217, 3, 104, 1)" // secondary brand color
const jazzberryJam = "rgba(165, 3, 80, 1)"
const toledo = "rgba(65, 1, 31, 1)"
const dogwoodRose004 = "rgba(217, 3, 104, 0.04)"
const dogwoodRose008 = "rgba(217, 3, 104, 0.08)"
const dogwoodRose016 = "rgba(217, 3, 104, 0.16)"

//system colors
const coralRed = "rgba(251, 54, 64, 1)" // danger
const lemonYellow = "rgba(247, 240, 82, 1)" // warning
const caribbeanGreen = "rgba(6, 214, 160, 1)" // success

//font is defined by typography.js
export const preset = {
         initialColorMode: "light",
         colors: {
           text: ebony,
           buttonText: romance,
           overlayText: romance,
           background: romance,
           primary: lightseaGreen,
           primaryDark: eden,
           primaryDarker: daintree,
           primaryLight: mediumTurquoise,
           primay004: lightseaGreen004,
           primary008: lightseaGreen008,
           primary016: lightseaGreen016,
           secondary: dogwoodRose,
           gray: "#555",
           muted: "#f6f6f6f",
           error: coralRed,
           warning: lemonYellow,
           success: caribbeanGreen,
           lightseaGreen: lightseaGreen,
           modes: {
             rose: {
               text: romance,
               buttonText: romance,
               overlayText: romance,
               background: ebony,
               primary: dogwoodRose,
               primaryDark: jazzberryJam,
               primaryDarker: toledo,
               primaryLight: rosebonbon,
               primay004: dogwoodRose004,
               primary008: dogwoodRose008,
               primary016: dogwoodRose016,
               secondary: lightseaGreen,
               gray: "#555",
               muted: "#f6f6f6f",
               error: coralRed,
               warning: lemonYellow,
               success: caribbeanGreen,
               lightseaGreen: lightseaGreen,
             },
             colorful: {
               text: romance,
               buttonText: romance,
               overlayText: romance,
               background: ebony,
               primary: eden,
               primaryDark: daintree,
               primaryDarker: ebony,
               primaryLight: rosebonbon,
               primay004: lightseaGreen004,
               primary008: lightseaGreen008,
               primary016: lightseaGreen016,
               secondary: jazzberryJam,
               gray: "#555",
               muted: "#f6f6f6f",
               error: coralRed,
               warning: lemonYellow,
               success: caribbeanGreen,
               lightseaGreen: lightseaGreen,
             },
             papaya: {
               // this color mode will fallback to the root color object
               // for values not defined here
               text: "#433",
               background: "papayawhip",
             },
           },
         },
         // variants for buttons
         buttons: {
           primary: {
             color: "buttonText",
             bg: "primary",
             borderStyle: "solid",
             borderColor: "primary",
             borderWidth: 2,
             whiteSpace: "nowrap",
             "&:hover": {
               color: "background",
               bg: "primaryDark",
               borderColor: "primaryDark",
             },
           },
           secondary: {
             color: "primary",
             bg: "transparent",
             borderStyle: "solid",
             borderColor: "primary",
             borderWidth: 2,
             "&:hover": {
               color: "background",
               bg: "primaryDark",
               borderColor: "primaryDark",
             },
           },
           simple: {
             color: "primary",
             bg: "transparent",
             borderColor: "transparent",
             "&:hover": {
               color: "primaryDark",
               bg: "transparent",
               borderColor: "transparent",
             },
           },
           gray: {
             color: "primaryDark",
             bg: "rgba(48, 53, 54, 0.05)",
             borderColor: "transparent",
           },
         },
         space,
         breakpoints,
         shadows: {
           card: "0 0 4px rgba(0, 0, 0, 0.125)",
         },
         variants: {
           card: {
             p: 2,
             bg: "background",
             boxShadow: "card",
             borderRadius: 2,
           },
           badge: {
             display: "inline-block",
             p: 1,
             color: "white",
             bg: "primary",
             borderRadius: 2,
           },
         },
         mediaQueries: {
           small: `@media screen and (min-width: ${breakpoints[0]})`,
           medium: `@media screen and (min-width: ${breakpoints[1]})`,
           large: `@media screen and (min-width: ${breakpoints[2]})`,
         },
       }

export default preset
